<script setup lang="ts">
import { type ILeaseDocumentField, useBacklink } from '@register'
import {
  useFieldSelection,
  useLeaseReviewWebViewer,
  useLeaseReviewFieldSubmit,
  useLeaseReviewSpecialConditionSubmit,
} from '@register/components/Review/composables'
import { openNotesModal } from '@register/components/NotesModal/composables/useNotesModal'
import { IEntity } from '~/composables/useAPI/types'
import { useLeaseReviewFieldDelete } from '@register/components/Review/composables/useLeaseReviewFieldDelete'

interface LeaseReviewFieldProps {
  id?: string
  field: ILeaseDocumentField
  specialCondition?: boolean
  large?: boolean
}
const props = withDefaults(defineProps<LeaseReviewFieldProps>(), {
  id: undefined,
  specialCondition: false,
  large: false,
})

// Get lease document
const leaseId = getCurrentLeaseId()
const { lease } = useCurrentLeaseDocument()

// Field selection
const { selectedField, select, unselect } = useFieldSelection()

// Backlink
const { selectedCopiedText, onCopyFromDocument } = useBacklink()

// Methods
const onAddNote = (field: ILeaseDocumentField): void => {
  selectedField.value = field
  openNotesModal(IEntity.LEASE, leaseId, field.name, field.id)
}

// SUBMIT
const useSubmit = props.specialCondition
  ? useLeaseReviewSpecialConditionSubmit
  : useLeaseReviewFieldSubmit
const { onSubmit } = useSubmit(props.id ?? props.field.id)

// DELETE
const onDelete = useLeaseReviewFieldDelete(props.id ?? props.field.id)

const classes = computed(() => {
  // TODO: BE should return the large property
  if (props.large) {
    return 'lg:col-span-2 w-full group relative flex w-full flex-col data-[schema=table]:col-span-full '
  }

  return 'w-full group relative flex w-full flex-col data-[schema=table]:col-span-full data-[schema=maps]:col-span-full data-[schema=exclusiveCheckbox]:col-span-full'
})
</script>

<template>
  <FormKit
    :id="id ?? field.id"
    type="lease-document"
    :name="id ?? field.id"
    :label="field.name"
    :field="field"
    :lease-settings="lease?.settings"
    :selected-text="selectedCopiedText"
    :outer-class="classes"
    dirty-behavior="compare"
    @input="onSubmit"
    @add-note="onAddNote(field)"
    @copy-from-document="onCopyFromDocument(field)"
    @backlinking-only="onCopyFromDocument(field)"
    @undo="select(field)"
    @redo="select(field)"
    @select="select(field)"
    @change="unselect()"
    @delete="onDelete"
  />
</template>
