import { toast as Vue3Toastify } from 'vue3-toastify'
import { useApiDeleteLeaseField } from '@register'

export const useLeaseReviewFieldDelete = (fieldId: string) => {
  /* === LEASE & DIVISION === */
  const divisionId = getCurrentDivisionId()
  const leaseId = getCurrentLeaseId()
  /* === LEASE & DIVISION === */

  /* === MUTATION === */
  const { mutateAsync: deleteField } = useApiDeleteLeaseField(
    divisionId,
    leaseId,
    fieldId,
  )
  /* === MUTATION === */

  return useConfirmHandler(() => deleteField(), {
    modal: {
      title: 'Delete field',
      body: 'Are you sure you want to delete this field?',
    },
    loadingMessage: 'Deleting field...',
    successMessage: 'Field deleted',
    errorMessage: 'Failed to delete field',
    toastOptions: {
      position: Vue3Toastify.POSITION.BOTTOM_RIGHT,
    },
  })
}
