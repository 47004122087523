import type { LeaseDocumentValue } from '@ui/formkit/inputs/leaseDocumentInput/types'
import { reset } from '@formkit/core'
import {
  useApiUpdateLeaseField,
  useApiAffectedFields,
  resetAffectedField,
  useFieldSubmitHandler,
} from '@register'

export const useLeaseReviewFieldSubmit = (fieldId: string) => {
  /* === LEASE & DIVISION === */
  const divisionId = getCurrentDivisionId()
  const leaseId = getCurrentLeaseId()
  /* === LEASE & DIVISION === */

  /* === MUTATION === */
  const { mutateAsync: saveField } = useApiUpdateLeaseField(
    divisionId,
    leaseId,
    fieldId,
  )
  /* === MUTATION === */

  const { data } = useApiAffectedFields(divisionId)
  const affectedFields = computed(() => data.value ?? {})

  const onSubmit = useFieldSubmitHandler<LeaseDocumentValue>(
    'lease',
    (data) => saveField(data),
    {
      onSuccess: (_, { node }) => {
        // Sync value
        const field = node?.context.field
        if (!node || !field) return

        if (node.context.isApproved && field.value?.value) {
          node.context.preventNextRequest = true
          const value: LeaseDocumentValue = {
            value: node.context.fieldConfig.toAPI(field.value.value),
            bounds: field.value.bounds
              ? {
                  bounds: field.value.bounds.bounds ?? [],
                  documentId: field.value.bounds.documentId,
                }
              : null,
            isApproved: true,
          }
          node.props.initial = node.props._init = value
          reset(node, value)
        }

        if (field.id in affectedFields.value) {
          for (const affectedFieldId of affectedFields.value[field.id]) {
            resetAffectedField(affectedFieldId)
          }
        }
      },
    },
  )

  return {
    onSubmit,
  }
}
