import { useMutation, useQueryClient } from '@tanstack/vue-query'
import type {
  ILeaseRegisterLease,
  IPatchLeaseDocumentFieldRequest,
} from '@register'

export function useApiUpdateLeaseField(
  companyId: string,
  leaseId: string,
  fieldId: string,
) {
  const queryClient = useQueryClient()

  let controller: AbortController | null = null
  let signal: AbortSignal | null = null

  return useMutation({
    mutationFn: async (body: IPatchLeaseDocumentFieldRequest) => {
      // If a previous request is in progress, abort it
      if (controller) {
        controller.abort()
      }

      // Create a new AbortController and signal for the upcoming request
      controller = new AbortController()
      signal = controller.signal

      return fetchAPI<ILeaseRegisterLease>(
        `/companies/${companyId}/leaseRegister/leases/${leaseId}/${fieldId}`,
        {
          method: 'PATCH',
          body,
          signal,
        },
      )
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['getLeaseDocumentId', companyId, leaseId], data)
      queryClient.invalidateQueries({
        queryKey: ['companies', companyId, 'leases', leaseId],
      })
    },
  })
}
