import { useMutation, useQueryClient } from '@tanstack/vue-query'

export function useApiDeleteLeaseField(
  companyId: string,
  leaseId: string,
  fieldId: string,
) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () =>
      fetchAPI<undefined>(
        `/companies/${companyId}/leaseRegister/leases/${leaseId}/specialConditions/${fieldId}`,
        {
          method: 'DELETE',
        },
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['getLeaseDocumentId', companyId, leaseId],
      })
      queryClient.invalidateQueries({
        queryKey: ['companies', companyId, 'leases', leaseId],
      })
    },
  })
}
