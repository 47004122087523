import type { LeaseDocumentValue } from '@ui/formkit/inputs/leaseDocumentInput/types'
import { useFieldSubmitHandler } from '@register'

export const useLeaseReviewSpecialConditionSubmit = (
  specialConditionId: string,
) => {
  /* === LEASE & DIVISION === */
  const divisionId = getCurrentDivisionId()
  const leaseId = getCurrentLeaseId()
  /* === LEASE & DIVISION === */

  /* === MUTATION === */
  const { mutateAsync: saveSpecialCondition } =
    useApiLeaseSpecialConditionUpdate(divisionId, leaseId, specialConditionId)
  /* === MUTATION === */

  const onSubmit = useFieldSubmitHandler<LeaseDocumentValue>(
    'lease',
    ({ value, bounds, isApproved }) =>
      saveSpecialCondition({
        value: value as string,
        bounds: bounds.bounds,
        isApproved,
      }),
  )

  return {
    onSubmit,
  }
}
